<template>
  <div class="tooltip">
    <div @click.stop="toggleShow"><slot name="component"></slot></div>
    <div :style="{ width: tooltipWidth }" v-if="show" class="tooltip-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tooltipWidth: {
      type: String,
      default: "auto",
    },
  },
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    //detect outside click and hide the dropdown menu
    //please note this will not get triggered when menu items itself are clicked
    //all click events inside this component have @click.stop defined which will prevent this listener from running
    document.addEventListener("click", this.hide);
  },
  destroyed() {
    //remove event listener as it is no longer required
    document.removeEventListener("click", this.hide);
  },
  methods: {
    toggleShow() {
      this.show = !this.show;
    },
    hide() {
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./ToolTip.scss";
</style>
